// gatsby-browser.js
require("prismjs/themes/prism-tomorrow.css");

exports.onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
      `Er is een nieuwe versie van mijn site beschikbaar en je gebruikt een offline versie. ` +
        `Wil je nu herladen voor de laatste versie?`
    )
  
    if (answer === true) {
      window.location.reload()
    }
  }